// src/components/UnderConstruction.js
import React, { useEffect, useState } from 'react';
import './UnderConstruction.css';

const UnderConstruction = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Check if the user is on a mobile device
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android|iPad|iPhone|iPod/i.test(userAgent.toLowerCase())) {
      setIsMobile(true);
    }
  }, []);

  return (
    <div className="container">
      <div className="content">
        <h1 className="shop-title">SKIN 'S VITAL</h1>
        <p>
          Η ιστοσελίδα μας βρίσκεται υπό κατασκευή!
          <br />
          {/* <h2>🚧 Under Construction 🚧</h2> */}
          {isMobile ? (
            <>
              Για οποιαδήποτε πληροφορία καλέστε στο{' '}
              <a href="tel:2105125100" style={{ color: '#E1306C', fontWeight: 'bold' }}>
                <strong>210 5125100</strong>
              </a>.
            </>
          ) : (
            <>Για οποιαδήποτε πληροφορία μπορείτε να καλέσετε στο <strong>210 5125100</strong>.</>
          )}
        </p>

        <div className="contact-info">         
          {isMobile ? (
            // Mobile link to open in Instagram app
            <a
              href="instagram://user?username=skinsvital_gr"
              className="instagram-button"
            >
              <i className="fab fa-instagram"></i>@skinsvital_gr
            </a>
          ) : (
            // Desktop link to open in browser
            <a
              href="https://www.instagram.com/skinsvital_gr/"
              target="_blank"
              rel="noopener noreferrer"
              className="instagram-button"
            >
              <i className="fab fa-instagram"></i>@skinsvital_gr
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default UnderConstruction;
